import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { TwoGrid } from '../components/Grid'
import NonStretchedImage from '../components/NonStretchedImage'
import { cleanPath } from '../utils/path'

const ImageWrap = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  @media(max-width: 768px) {
    order: -1;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
`
const TextWrap = styled.div`
  padding-right: 30px;
  @media(max-width: 768px) {
    padding-right: 0px;
  }
`

const RightImageSection = ({ data, bgClass }) => (
  <section className={`section  ${bgClass}`}>
    <div className="container">
      <TwoGrid>
        <TextWrap>
          <h2 className="section-title is-primary-text">
            {data.title}
          </h2>
          <p className="section-description is-black-text">
            {data.description}
          </p>
          {data.linkNewWindow?(
            <a href={cleanPath(data.buttonLink)} target="_blank" rel="noreferrer">
              <button className="is-primary">
                {data.buttonTxt}
              </button>
            </a>
          ):(
            <Link to={cleanPath(data.buttonLink)}>
              <button className="is-primary">
                {data.buttonTxt}
              </button>
            </Link>
          )}
        </TextWrap>
        <ImageWrap className="row-reverse">
          <NonStretchedImage
            objectFit="contain"
            alt={data.title}
            className=""
            {...data.image}
          />
        </ImageWrap>
      </TwoGrid>
    </div>
  </section>
)

export default RightImageSection  