import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import HeroOther from '../components/HeroOther'
import { ThreeGrid } from '../components/Grid'
import { UsecaseHeroTriangle } from '../components/Triangle'
import Card from '../components/Card'
import FeatureCard from '../components/FeatureCard'
import CtaSection from '../components/CtaSection'

import RightImageSection from '../sections/RightImageSection'
import LeftImageSection from '../sections/LeftImageSection'

export const UseCasesPageTemplate = ({
  hero,
  twoColumnsSections,
  advantagesSection,
  scenariosSection,
}) => (
  <div>
    <HeroOther data={hero}/>
    <UsecaseHeroTriangle />
    <div>
      {
        twoColumnsSections.map((sectionItem, i)=> {
          if (i % 2 === 0 ) {
            return (<RightImageSection data={sectionItem} bgClass="is-white" />)
          }
          return (<LeftImageSection data={sectionItem} bgClass="is-gray-bg" />)
        })
      }
    </div>
    <section className="section">
      <div className="container">
        <h2 className="section-title is-primary-text has-text-centered">
          {advantagesSection.title}
        </h2>
        <ThreeGrid>
          {advantagesSection.list.map(item=>{
            return(
              <FeatureCard cardData={item} />
            )
          })}
        </ThreeGrid>
      </div>
    </section>
    <section className="section is-black">
      <div className="container">
        <h2 className="section-title is-white-text has-text-centered">
          {scenariosSection.title}
        </h2>
        <ThreeGrid>
          {scenariosSection.list.map(item=>{
            return(
              <Card cardData={item} />
            )
          })}
        </ThreeGrid>
      </div>
    </section>
    <CtaSection bgClassName="is-gray-bg" />
  </div>
)

UseCasesPageTemplate.propTypes = {
  hero: PropTypes.object,
  twoColumnsSections: PropTypes.object,
  advantagesSection: PropTypes.object,
  scenariosSection: PropTypes.object,
}

const UseCasesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO 
        title={frontmatter.seoTitle}
        description={frontmatter.seoDescription}
      />
      <UseCasesPageTemplate
        hero={frontmatter.hero}
        twoColumnsSections={frontmatter.twoColumnsSections}
        advantagesSection={frontmatter.advantagesSection}
        scenariosSection={frontmatter.scenariosSection}
      />
    </Layout>
  )
}

UseCasesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default UseCasesPage

export const pageQuery = graphql`
  query UseCasesPageById($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 534, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          buttons {
            text
            link
            linkNewWindow
            bg
          }
        }
        twoColumnsSections {
          title
          description
          buttonTxt
          buttonLink
          linkNewWindow
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 350, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
        }
        advantagesSection {
          title
          list {
            item
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
        scenariosSection {
          title
          list {
            item
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
